import { SimpleGrid } from "@mantine/core";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import StatCard from "../../components/StatCard";
import { setHeaderTitle } from "../../store/modules/static";

const Home = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      setHeaderTitle({
        title: "Dashboard",
        subtitle: "Welcome, Toluwanimi",
      })
    );
  }, [dispatch]);

  return (
    <div>
      {/* <Group position="right" mb={30}>
        <SegmentedControl
          data={[
            { label: "9th Cohort", value: "9" },
            { label: "10th Cohort", value: "10" },
            { label: "11th Cohort", value: "11" },
          ]}
        />
      </Group> */}
      <SimpleGrid cols={4}>
        {/* <StatCard stat={{ title: "Total Users", value: "1,234" }} />
        <StatCard stat={{ title: "Product/UX Design", value: "1,234" }} />
        <StatCard stat={{ title: "Data Science", value: "1,234" }} />
        <StatCard
          stat={{ title: "Frontend Web Development", value: "1,234" }}
        />
        <StatCard stat={{ title: "NFT & Blockchain", value: "1,234" }} /> */}
      </SimpleGrid>
    </div>
  );
};

export default Home;
