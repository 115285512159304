const quizApi = (axios) => {
  return {
    create: async (data) => {
      try {
        const req = await axios.post(`/quiz/`, data);
        return req.data;
      } catch (error) {
        throw error?.response?.data;
      }
    },
    update: async (id, data) => {
      try {
        const req = await axios.put(`/quiz/${id}`, data);
        return req.data;
      } catch (error) {
        throw error?.response?.data;
      }
    },
    getAll: async () => {
      try {
        const req = await axios.get(`/quiz/all`);
        return req.data;
      } catch (error) {
        throw error?.response?.data;
      }
    },
    getOne: async (id) => {
      try {
        const req = await axios.get(`/quiz/${id}`);
        return req.data;
      } catch (error) {
        throw error?.response?.data;
      }
    },
    getQuizByCourse: async (id) => {
      try {
        const req = await axios.get(`/quiz/course/${id}`);
        return req.data;
      } catch (error) {
        throw error?.response?.data;
      }
    }
  };
};

export default quizApi;
