const emailApi = (axios) => {
  return {
    send: async (data) => {
      try {
        const req = await axios.post("/email/send", data);
        return req.data;
      } catch (error) {
        throw error?.response?.data;
      }
    },
  };
};

export default emailApi;
