const userApi = (axios) => {
  return {
    getAll: async () => {
      try {
        const req = await axios.get("/user/subscribed-users");
        return req.data;
      } catch (error) {
        throw error?.response?.data;
      }
    },
  };
};

export default userApi;
