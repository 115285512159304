import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setHeaderTitle } from "../../store/modules/static";
import { Box, Group, Table, TextInput } from "@mantine/core";
import { useQuery } from "@tanstack/react-query";
import courseApi from "../../services/api/course";
import userApi from "../../services/api/users";
import useAxios from "../../hooks/axios";

const Users = () => {
  const dispatch = useDispatch();
  const { api: axiosApi } = useAxios();
  const users = useQuery(["course"], () => userApi(axiosApi).getAll());
  const [filteredUsers, setFilteredUsers] = React.useState([]);
  const [search, setSearch] = React.useState("");

  useEffect(() => {
    dispatch(
      setHeaderTitle({
        title: "Users",
        subtitle: "View users info",
      })
    );
  }, [dispatch]);

  useEffect(() => {
    if (users?.data?.data?.users) {
      setFilteredUsers(
        users?.data?.data?.users.filter(
          (u) =>
            u.user.firstName
              .toLowerCase()
              .includes(search.trim().toLowerCase()) ||
            u.user.lastName
              .toLowerCase()
              .includes(search.trim().toLowerCase()) ||
            u.user.email.toLowerCase().includes(search.trim().toLowerCase()) ||
            u.course[0]?.title
              ?.toLowerCase()
              .includes(search.trim().toLowerCase())
        )
      );
    }
  }, [users.data, search]);

  return (
    <Box>
      <Group>
        <Box
          sx={{
            width: "350px",
            marginBottom: 25,
          }}
        >
          <TextInput
            placeholder="Search"
            onChange={(e) => setSearch(e.target.value)}
          />
        </Box>
        <Box>RESULT: {filteredUsers.length}</Box>
      </Group>
      <Table>
        <thead>
          <tr>
            <th>Full Name</th>
            <th>Email</th>
            <th>Phone Number</th>
            <th>Course</th>
            <th>Classes</th>
            <th>Classes Touch</th>
            <th>Watch Time</th>
            <th>A. Watch Time</th>
            <th>% Class Taken</th>
            <th>% Watch Time</th>
          </tr>
        </thead>
        <tbody>
          {filteredUsers.map((user) => (
            <>
              <tr key={user.user._id}>
                <td>
                  {user.user.firstName} {user.user.lastName}
                </td>
                <td>{user.user.email}</td>
                <td>{truncateString(user.user.phoneNumber)}</td>
                <td>{truncateString(user.course[0].title)}</td>
                <td>{user.allClasses}</td>
                <td>{user.totalTakenClasses}</td>
                <td>{secondsToHours(user.totalWatchDuration)}</td>
                <td>{secondsToHours(user.allClassesDuration)}</td>
                <td>{Number(user.totalClassTakenInPercentage).toFixed(2)}%</td>
                <td>
                  {Number(user.totalWatchedDurationInPercentage).toFixed(2)}%
                </td>
              </tr>
            </>
          ))}
        </tbody>
      </Table>
    </Box>
  );
};

export default Users;

function secondsToMins(seconds) {
  if (!seconds) {
    return "0m 0s";
  }

  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  return `${minutes}m ${remainingSeconds}s`;
}

function secondsToHours(seconds) {
  if (!seconds) {
    return "0h 0m 0s";
  }

  const hours = Math.floor(seconds / 3600);
  const remainingMinutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;
  return `${hours}h ${remainingMinutes}m ${Math.floor(remainingSeconds)}s`;
}

function truncateString(text, length = 11) {
  if (!text) {
    return "";
  }

  if (text.length > length) {
    return text.substring(0, length) + "...";
  } else {
    return text;
  }
}
