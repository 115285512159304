const courseApi = (axios) => {
  return {
    create: async (data) => {
      try {
        const req = await axios.post("/course", data);
        return req.data;
      } catch (error) {
        throw error?.response?.data;
      }
    },
    getAll: async () => {
      try {
        const req = await axios.get("/course/admin/courses");
        return req.data;
      } catch (error) {
        throw error?.response?.data;
      }
    },
    getOne: async (id) => {
      try {
        const req = await axios.get(`/course/admin/courses/${id}`);
        return req.data;
      } catch (error) {
        throw error?.response?.data;
      }
    },
    getContents: async (id) => {
      try {
        const req = await axios.get(`/course/${id}/content`);
        return req.data;
      } catch (error) {
        throw error?.response?.data;
      }
    },
    deleteCourse: async (id) => {
      try {
        const req = await axios.delete(`/course/${id}`);
        return req.data;
      } catch (error) {
        throw error?.response?.data;
      }
    },
  };
};

export default courseApi;
