import {
  AppShell,
  Box,
  Button,
  createStyles,
  Group,
  Text,
  useMantineTheme,
} from "@mantine/core";
import { useSelector } from "react-redux";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";

import useAuth from "../hooks/auth";
import { NavbarNested } from "../components/Navbar/Navbar";
import { getHeaderTitle } from "../store/modules/static";
import { useDispatch } from "react-redux";
import {
  IconArrowBack,
  IconArrowBarRight,
  IconArrowNarrowLeft,
} from "@tabler/icons";

const useStyle = createStyles((theme) => ({
  gridWrapper: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
    justifyContent: "center",
    alignItems: "center",
  },
  mainLayout: {
    display: "block",
    width: "100%",
  },
}));

const Dashboard = () => {
  const auth = useAuth();
  const { classes } = useStyle();
  const theme = useMantineTheme();
  const headerTitle = useSelector(getHeaderTitle);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  if (!auth.user) {
    return <Navigate to="/" replace />;
  }

  const locationIsChild = location.pathname.split("/").length > 3;

  return (
    <>
      <AppShell
        navbarOffsetBreakpoint="sm"
        navbar={<NavbarNested isNavOpen={false} />}
        sx={{
          backgroundColor: theme.colors.gray[0],
        }}
      >
        <Box className={classes.mainLayout}>
          <Box sx={{ height: "70px" }}>
            <Group position="apart">
              <Group align="center">
                {locationIsChild && (
                  <div
                    onClick={() => navigate(-1)}
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    <IconArrowNarrowLeft size={24} />
                  </div>
                )}
                <div>
                  <Text size="lg" weight={600} color="dark">
                    {headerTitle.title}
                  </Text>
                  <Text size="sm" color="gray">
                    {headerTitle.subtitle}
                  </Text>
                </div>
              </Group>
              <Box>
                <Button
                  color="red"
                  onClick={() => {
                    dispatch(auth.logout());
                  }}
                >
                  Logout
                </Button>
              </Box>
            </Group>
          </Box>
          <Outlet />
        </Box>
      </AppShell>
    </>
  );
};

export default Dashboard;
