import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  headerTitle: {
    title: "Dashboard",
    subtitle: "Welcome Toluwanimi",
  },
};

export const staticSlice = createSlice({
  name: "static",
  initialState,
  reducers: {
    setHeaderTitle: (state, action) => {
      state.headerTitle = action.payload;
    },
  },
});

export const { setHeaderTitle } = staticSlice.actions;

export const getHeaderTitle = (state) => state.static.headerTitle;

export default staticSlice.reducer;
