import { Modal } from "@mantine/core";
import React from "react";
import VideoJS from "./VideoJS";

const VideoPlayer = ({ isOpened, onClose, title, videoInfo }) => {
  const playerRef = React.useRef(null);

  const videoType = videoInfo?.name?.split(".")?.pop();
  const addHttp = (url) => {
    if (!/^(f|ht)tps?:\/\//i.test(url)) {
      url = "https://" + url;
    }
    return url;
  };

  const videoJsOptions = {
    autoplay: true,
    controls: true,
    responsive: true,
    fluid: true,
    sources: [
      {
        src: addHttp(videoInfo?.url),
        type: `video/${videoType}`,
      },
    ],
  };

  const handlePlayerReady = (player) => {
    playerRef.current = player;

    // You can handle player events here, for example:
    player.on("waiting", () => {
      console.log("player is waiting");
    });

    player.on("dispose", () => {
      console.log("player will dispose");
    });
  };

  return (
    <Modal
      opened={isOpened}
      onClose={onClose}
      title={title}
      centered
      size="lg"
      overflow="inside"
    >
      <VideoJS options={videoJsOptions} onReady={handlePlayerReady} />
    </Modal>
  );
};

export default VideoPlayer;
