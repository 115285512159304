const questionApi = (axios) => {
  return {
    upload: async (data) => {
      try {
        const req = await axios.post("/questions/upload", data, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        return req.data;
      } catch (error) {
        throw error?.response?.data;
      }
    },
    getAll: async (courseId) => {
      try {
        const req = await axios.get(`/questions/all/${courseId}`);
        return req.data;
      } catch (error) {
        throw error?.response?.data;
      }
    },
    getAllByQuiz: async (courseId) => {
      try {
        const req = await axios.get(`/questions/quiz/${courseId}`);
        return req.data;
      } catch (error) {
        throw error?.response?.data;
      }
    },
    getOne: async (id) => {
      try {
        const req = await axios.get(`/questions/${id}`);
        return req.data;
      } catch (error) {
        throw error?.response?.data;
      }
    },
    create: async (data) => {
      try {
        const req = await axios.post("/questions", data);
        return req.data;
      } catch (error) {
        throw error?.response?.data;
      }
    },
    update: async (id, data) => {
      try {
        const req = await axios.put(`/questions/${id}`, data);
        return req.data;
      } catch (error) {
        throw error?.response?.data;
      }
    },
  };
};

export default questionApi;
