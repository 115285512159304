import { Group, Paper, Text, ThemeIcon } from "@mantine/core";
import { IconArrowUpRight } from "@tabler/icons";

const StatCard = ({ stat }) => {
  return (
    <Paper withBorder p="md" radius="md" key={stat.title}>
      <Group position="apart">
        <div>
          <Text color="dimmed" transform="uppercase" weight={700} size="xs">
            {stat.title}
          </Text>
          <Text weight={700} size="xl">
            {stat.value}
          </Text>
        </div>
        <ThemeIcon
          color="gray"
          variant="light"
          sx={(theme) => ({
            color: theme.colors.teal[6],
          })}
          size={38}
          radius="md"
        >
          <IconArrowUpRight size={28} stroke={1.5} />
        </ThemeIcon>
      </Group>
    </Paper>
  );
};

export default StatCard;
