import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Box,
  Button,
  Card,
  Group,
  Loader,
  SimpleGrid,
  Text,
  Modal,
  TextInput,
  Select,
  FileInput,
} from "@mantine/core";
import { IconPlus, IconTrash } from "@tabler/icons";
import { setHeaderTitle } from "../../../store/modules/static";
import useQueryParams from "../../../hooks/useQueryParams";
import { useLocation, useParams } from "react-router-dom";
import questionApi from "../../../services/api/question";
import useAxios from "../../../hooks/axios";
import { useMutation, useQuery } from "@tanstack/react-query";
import { showNotification } from "@mantine/notifications";

const ManageQuestion = () => {
  const { api: axiosApi } = useAxios();
  const dispatch = useDispatch();
  const location = useLocation();
  const params = useParams();
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [file, setFileInput] = useState(null);
  const [uploading, setUploading] = useState(false);

  const query = useQueryParams(location);
  const questions = useQuery(["questions", params.courseId], () =>
    questionApi(axiosApi).getAllByQuiz(params.courseId)
  );

  useEffect(() => {
    dispatch(
      setHeaderTitle({
        title: `${query.course} Questions`,
        subtitle: "Manage all your questions",
      })
    );
  }, [dispatch, query.course]);

  const questionMutation = useMutation((question) => {
    return questionApi(axiosApi).update(question._id, question);
  });

  const handleQuestionUpdate = async () => {
    try {
      await questionMutation.mutateAsync(selectedQuestion);
      showNotification({
        title: "Successful",
        message: "Question updated successfully",
        color: "green",
      });
      setSelectedQuestion(null);
      questions.refetch();
    } catch (error) {
      showNotification({
        title: "Error",
        message: error.message,
      });
    }
  };

  const handleUpload = async () => {

    console.log(file);

    if (!file) return;

    setUploading(true);
    const formData = new FormData();
    formData.append("file", file);
    formData.append("course", params.courseId);

    try {
      const req = await questionApi(axiosApi).upload(formData);
      showNotification({
        title: req.message,
        message: "Question uploaded successfully",
        color: "green",
      });
      setFileInput(null);
      setUploading(false);
      questions.refetch();
    } catch (error) {
      showNotification({
        title: "Error",
      })
    }
  };

  if (questions.isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "80vh",
        }}
      >
        <Loader />
      </Box>
    );
  }

  return (
    <>
      <div>
        {selectedQuestion !== null && (
          <Modal
            opened={selectedQuestion !== null}
            onClose={() => setSelectedQuestion(null)}
            size="lg"
            title="Edit Question"
          >
            <div className="space-y-2">
              <TextInput
                label="Question"
                value={selectedQuestion.question}
                onChange={(e) => {
                  setSelectedQuestion({
                    ...selectedQuestion,
                    question: e.target.value,
                  });
                }}
              />

              <TextInput
                label="Option 1"
                value={selectedQuestion.options[0]}
                onChange={(e) => {
                  setSelectedQuestion({
                    ...selectedQuestion,
                    options: [
                      e.target.value,
                      selectedQuestion.options[1],
                      selectedQuestion.options[2],
                      selectedQuestion.options[3],
                    ],
                  });
                }}
              />
              <TextInput
                label="Option 2"
                value={selectedQuestion.options[1]}
                onChange={(e) => {
                  setSelectedQuestion({
                    ...selectedQuestion,
                    options: [
                      selectedQuestion.options[0],
                      e.target.value,
                      selectedQuestion.options[2],
                      selectedQuestion.options[3],
                    ],
                  });
                }}
              />
              <TextInput
                label="Option 3"
                value={selectedQuestion.options[2]}
                onChange={(e) => {
                  setSelectedQuestion({
                    ...selectedQuestion,
                    options: [
                      selectedQuestion.options[0],
                      selectedQuestion.options[1],
                      e.target.value,
                      selectedQuestion.options[3],
                    ],
                  });
                }}
              />
              <TextInput
                label="Option 4"
                value={selectedQuestion.options[3]}
                onChange={(e) => {
                  setSelectedQuestion({
                    ...selectedQuestion,
                    options: [
                      selectedQuestion.options[0],
                      selectedQuestion.options[1],
                      selectedQuestion.options[2],
                      e.target.value,
                    ],
                  });
                }}
              />

              <Select
                label="Answer"
                value={selectedQuestion.answer}
                onChange={(value) => {
                  setSelectedQuestion({
                    ...selectedQuestion,
                    answer: value,
                  });
                }}
                data={[
                  ...selectedQuestion.options.map((option) => ({
                    label: option,
                    value: option,
                  })),
                ]}
              />

              <div className="mt-12 flex justify-end">
                <Button onClick={handleQuestionUpdate}>Save</Button>
              </div>
            </div>
          </Modal>
        )}

        {/* <div className="mt-10 flex items-end justify-end">
          <div className="mr-5">
            <FileInput
              name="Upload"
              placeholder="Upload Question"
              label="Upload question"
              accept=".txt"
              onChange={(file) => {
                setFileInput(file);
              }}
              sx={{
                width: 300,
              }}
            />
          </div>
          <Button leftIcon={<IconPlus size={20} />} onClick={handleUpload}>
            <span className="ml-2" >Upload Question</span>
          </Button>
        </div> */}

        <SimpleGrid cols={3} sx={{ marginTop: 20 }}>
          {questions.data.data.map((question) => (
            <>
              <Card
                key={question._id}
                withBorder
                p="lg"
                radius="md"
                shadow="sm"
                sx={{ backgroundColor: "white" }}
              >
                <Text size="lg" weight={500} sx={{ marginBottom: 12 }}>
                  {question.question}
                </Text>
                <div className="grid grid-cols-2 gap-2">
                  {question.options.map((option) => (
                    <div
                      className={`flex items-center  p-2 px-4 rounded ${
                        question.answer === option
                          ? "bg-green-100"
                          : "bg-gray-100"
                      } `}
                    >
                      <Text size="sm">{option}</Text>
                    </div>
                  ))}
                </div>
                <div className="flex justify-end gap-x-2 mt-4">
                  {/* <Button
                    variant="outline"
                    color="red"
                    size="sm"
                    className="mt-4"
                    onClick={() => {}}
                  >
                    Delete
                  </Button> */}
                  <Button
                    variant="outline"
                    color="blue"
                    size="sm"
                    className="mt-4"
                    onClick={() => {
                      setSelectedQuestion(question);
                    }}
                  >
                    Edit
                  </Button>
                </div>
              </Card>
            </>
          ))}
        </SimpleGrid>
      </div>
    </>
  );
};

export default ManageQuestion;
