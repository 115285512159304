import { createContext, useContext } from "react";
import { useLocalStorage } from "@mantine/hooks";
import { useState } from "react";

const AuthContext = createContext({
  user: null,
  login: () => {},
  logout: () => {},
});

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useLocalStorage({
    key: "admin-user",
    defaultValue: null,
  });

  // call this function when you want to authenticate the user
  const login = async (data, callback) => {
    setUser(data);
    console.log(data);
    callback();
  };

  // call this function to sign out logged in user
  const logout = (callback) => {
    setUser(null);
    if (callback) {
      callback();
    }
  };

  const value = {
    user,
    login,
    logout,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

const useAuth = () => {
  return useContext(AuthContext);
};

export default useAuth;
