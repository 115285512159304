import {
  Box,
  Button,
  Card,
  Group,
  Loader,
  Modal,
  SimpleGrid,
  Text,
} from "@mantine/core";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { IconPlus, IconTrash } from "@tabler/icons";

import { setHeaderTitle } from "../../store/modules/static";
import useAxios from "../../hooks/axios";
import { useMutation, useQuery } from "@tanstack/react-query";
import courseApi from "../../services/api/course";
import { Link } from "react-router-dom";
import quizApi from "../../services/api/quiz";

const Questions = () => {
  const { api: axiosApi } = useAxios();
  const courses = useQuery(["course"], () => courseApi(axiosApi).getAll());
  const [selectedCourse, setSelectedCourse] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      setHeaderTitle({
        title: "Manage Questions",
        subtitle: "Kindly select a course to see questions",
      })
    );
  }, [dispatch]);

  const getQuizByCourse = useQuery(
    ["course", selectedCourse],
    () => quizApi(axiosApi).getQuizByCourse(selectedCourse),
    {
      enabled: !!selectedCourse,
    }
  );

  if (courses.isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "80vh",
        }}
      >
        <Loader />
      </Box>
    );
  }

  return (
    <>
      <Modal opened={!!selectedCourse} onClose={() => setSelectedCourse(null)}>
        {getQuizByCourse.isLoading ? (
          <div>
            <Loader />
          </div>
        ) : (
          <>
            {console.log(getQuizByCourse.data)}
            <SimpleGrid cols={1} sx={{ marginTop: 20 }}>
              {getQuizByCourse.data.data.map((quiz) => {
                return (
                  <Card
                    key={quiz._id}
                    withBorder
                    p="lg"
                    radius="md"
                    sx={{
                      position: "relative",
                    }}
                  >
                    <Box
                      mb="sm"
                      component={Link}
                      to={`/dashboard/questions/${quiz._id}?course=${quiz.course.title}`}
                    >
                      {quiz.title}
                    </Box>
                  </Card>
                );
              })}
            </SimpleGrid>
          </>
        )}
      </Modal>

      <SimpleGrid cols={3} sx={{ marginTop: 20 }}>
        {courses.data.data.courses.map((course) => {
          return (
            <Card
              key={course._id}
              withBorder
              p="lg"
              radius="md"
              sx={{
                position: "relative",
              }}
            >
              <Box
                mb="sm"
                sx={{
                  cursor: "pointer",
                }}
                // component={Link}
                onClick={() => setSelectedCourse(course._id)}
                // to={`/dashboard/questions/${course._id}?course=${course.title}`}
              >
                <Text weight={500}>{course.title}</Text>
              </Box>
            </Card>
          );
        })}
      </SimpleGrid>
    </>
  );
};

export default Questions;

function secondsToHours(seconds) {
  return Number(seconds / 3600).toFixed(2);
}
