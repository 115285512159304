/**
 * import { AxiosInstance } from "axios"
 * Axios
 *
 * @param {import('axios').AxiosInstance} axios
 * @returns
 */
const classApi = (axios) => {
  return {
    create: async (data, setUploadProgress) => {
      const formData = new FormData();
      formData.append("title", data.title);
      formData.append("instructor", data.instructor);
      formData.append("description", data.description);
      formData.append("course", data.course);
      formData.append("file", data.file);

      try {
        const req = await axios.post("/class", formData, {
          onUploadProgress: (progressEvent) => {
            const { loaded, total } = progressEvent;
            let percent = Math.floor((loaded * 100) / total);
            console.log(`${loaded}kb of ${total}kb | ${percent}%`);
            setUploadProgress((prev) => {
              return {
                ...prev,
                percent,
                loaded,
                total,
              };
            });
          },
        });
        return req.data;
      } catch (error) {
        throw error?.response?.data;
      }
    },
    getAll: async () => {
      try {
        const req = await axios.get("/class");
        return req.data;
      } catch (error) {
        throw error?.response?.data;
      }
    },
    getOne: async (id) => {
      try {
        const req = await axios.get(`/class/${id}`);
        return req.data;
      } catch (error) {
        throw error?.response?.data;
      }
    },
    getByCourse: async (courseId) => {
      try {
        const req = await axios.get(`/class/${courseId}/course`);
        return req.data;
      } catch (error) {
        throw error?.response?.data;
      }
    },
    deleteOne: async (id) => {
      console.log(id);
      try {
        const req = await axios.delete(`/class/${id}`);
        return req.data;
      } catch (error) {
        throw error?.response?.data;
      }
    },
  };
};

export default classApi;
