import {
  ActionIcon,
  Box,
  Button,
  Card,
  CopyButton,
  Grid,
  Group,
  Loader,
  Menu,
  SimpleGrid,
  Text,
} from "@mantine/core";
import { IconDots, IconPlus, IconTrash, IconVideo } from "@tabler/icons";
import { useMutation, useQuery } from "@tanstack/react-query";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import useAxios from "../../../hooks/axios";
import courseApi from "../../../services/api/course";
import { setHeaderTitle } from "../../../store/modules/static";
import CreateClass from "../../../components/course/CreateClass";
import classApi from "../../../services/api/class";
import VideoPlayer from "../../../components/VideoPlayer";

const CourseById = () => {
  const { api: axiosApi } = useAxios();
  const params = useParams();

  const [isVideoPlayerOpened, setVideoPlayerOpened] = React.useState(false);
  const [selectedVideo, setSelectedVideo] = React.useState(null);

  const course = useQuery([`course-${params.id}`], () => {
    return courseApi(axiosApi).getOne(params.id);
  });

  // const classes = useQuery([`course-${params.id}-classes`], () => {
  //   return classApi(axiosApi).getByCourse(params.id);
  // });

  const classDeleteMutation = useMutation(async (classId) => {
    return await classApi(axiosApi).deleteOne(classId);
  });

  const dispatch = useDispatch();

  const courseTitle = course?.data?.data?.course[0].title;

  console.log(courseTitle);

  useEffect(() => {
    dispatch(
      setHeaderTitle({
        title: `${courseTitle ? courseTitle : "Loading..."}`,
        subtitle: `${courseTitle ? `Manage contents in ${courseTitle}` : ""}`,
      })
    );
  }, [dispatch, courseTitle]);

  const onDeleteClass = async (id) => {
    await classDeleteMutation.mutateAsync(id);
    // await classes.refetch();
  };

  const onShowVideoPlayer = (video) => {
    setSelectedVideo({
      title: video?.title,
      url: video?.cdnUrl,
      name: video?.title,
    });
    setVideoPlayerOpened(true);
  };

  const slugify = (text) => {
    return text
      .toString()
      .toLowerCase()
      .replace(/\s+/g, "-") // Replace spaces with -
      .replace(/[^\w-]+/g, "") // Remove all non-word chars
      .replace(/--+/g, "-") // Replace multiple - with single -
      .replace(/^-+/, "") // Trim - from start of text
      .replace(/-+$/, ""); // Trim - from end of text
  };

  if (course.isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100vh",
        }}
      >
        <Loader />;
      </Box>
    );
  }

  return (
    <>
      {/* <CreateClass
        isOpened={isCreateClassModalOpened}
        onClose={() => {
          setCreateClassModalOpened(false);
        }}
        onCreated={() => {
          setCreateClassModalOpened(false);
        }}
        courseId={params.id}
      /> */}
      {isVideoPlayerOpened && (
        <VideoPlayer
          isOpened={isVideoPlayerOpened}
          title={selectedVideo.title}
          onClose={() => setVideoPlayerOpened(false)}
          videoInfo={selectedVideo}
        />
      )}
      {/* <Group position="right">
        <Button
          leftIcon={<IconPlus />}
          onClick={() => {
            setCreateClassModalOpened(true);
          }}
        >
          Create Class
        </Button>
      </Group> */}
      <Box sx={{ marginTop: 20 }}>
        <SimpleGrid cols={4}>
          {course?.data?.data?.course[0]?.classes.map((classItem) => (
            <Card key={classItem._id}>
              <Group position="apart" mt="md">
                <Text weight={500}>{classItem.title}</Text>
              </Group>

              <div>
                <Text size="sm" color="gray">
                  Duration: {secondToMins(classItem.videoData.duration)}
                </Text>
                <Text size="sm" color="gray">
                  Total Watch Time:{" "}
                  {secondToMins(
                    course?.data?.data?.course[0]?.progress?.find(
                      (item) => item._id === classItem._id
                    )?.totalProgress
                  )}
                </Text>
              </div>

              <Grid position="center" mt={20}>
                <Grid.Col span={8}>
                  <Button
                    fullWidth
                    leftIcon={<IconVideo />}
                    onClick={() => onShowVideoPlayer(classItem)}
                  >
                    Preview
                  </Button>
                </Grid.Col>
                <Grid.Col span={4}>
                  <CopyButton value={`https://bbl.ng/class/${classItem._id}`}>
                    {({ copied, copy }) => (
                      <Button
                        variant="filled"
                        color="blue"
                        fullWidth
                        radius="md"
                        onClick={copy}
                      >
                        {copied ? "Copied!" : "Copy link"}
                      </Button>
                    )}
                  </CopyButton>
                </Grid.Col>
              </Grid>
            </Card>
          ))}
        </SimpleGrid>
      </Box>
    </>
  );
};

export default CourseById;

function secondToMins(seconds) {
  if (!seconds) {
    return "0m 0s";
  }

  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  return `${minutes}m ${Math.floor(remainingSeconds)}s`;
}
