import {
  Box,
  Button,
  createStyles,
  PasswordInput,
  TextInput,
  Text,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { showNotification } from "@mantine/notifications";
import { useMutation } from "@tanstack/react-query";
import { Navigate, useNavigate } from "react-router-dom";
import useAuth from "../hooks/auth";
import useAxios from "../hooks/axios";
import authApi from "../services/api/auth";

const useStyles = createStyles((theme, _params, getRef) => ({
  wrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100vh",
  },
  formContainer: {
    width: "100%",
    maxWidth: "400px",
    padding: "20px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const Login = () => {
  const { classes } = useStyles();
  const { user, login } = useAuth();
  const navigate = useNavigate();
  const { api: axiosApi } = useAxios();

  const loginMutation = useMutation(
    (loginDetails) => {
      return authApi(axiosApi).login(loginDetails);
    },
    {
      retry: false,
      onError: (error) => {
        showNotification({
          title: "Error",
          message: error.message,
          color: "red",
        });
      },
      onSuccess: (data) => {
        showNotification({
          title: "Success",
          message: "Login Successful",
          color: "green",
        });
        login({ user: data.data.admin, token: data.data.token }, () => {
          navigate("/dashboard/home");
        });
      },
    }
  );

  const form = useForm({
    initialValues: { email: "", password: "" },
    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : "Invalid email"),
      password: (value) => (value.length < 6 ? "Password too short" : null),
    },
  });

  const handleLogin = async (values, form) => {
    loginMutation.mutateAsync(values);
  };

  if (user) {
    return <Navigate to="/dashboard/home" replace />;
  }

  return (
    <>
      <Box className={classes.wrapper} onSubmit={form.onSubmit(handleLogin)}>
        <div className={classes.formContainer}>
          <Text size="lg" weight={800} sx={{ textAlign: "left" }}>
            Login
          </Text>
          <Text size="sm" sx={{ textAlign: "left" }}>
            Please enter your credentials
          </Text>
          <form style={{ width: "100%" }}>
            <TextInput
              label="Email"
              placeholder="your@email.com"
              {...form.getInputProps("email")}
            />
            <PasswordInput
              sx={{ marginTop: 15 }}
              label="Password"
              {...form.getInputProps("password")}
            />
            <Button
              sx={{ marginTop: 30, width: "100%" }}
              type="submit"
              loading={loginMutation.isLoading}
            >
              Login
            </Button>
          </form>
        </div>
      </Box>
    </>
  );
};

export default Login;
