import {
  Navbar,
  Group,
  ActionIcon,
  ScrollArea,
  createStyles,
  useMantineColorScheme,
  Text,
} from "@mantine/core";
import {
  IconNotes,
  IconGauge,
  IconSun,
  IconMailForward,
  IconMoonStars,
  IconQuestionMark,
  IconUserCircle,
  IconZoomQuestion,
} from "@tabler/icons";
import { UserButton } from "./UserButton";
import { LinksGroup } from "./NavbarLinksGroup";

const mockdata = [
  { label: "Dashboard", icon: IconGauge, link: "/dashboard/home" },
  { label: "Users", icon: IconUserCircle, link: "/dashboard/users" },
  {
    label: "Course",
    icon: IconNotes,
    initiallyOpened: true,
    link: "/dashboard/course",
  },
  { label: "Email", icon: IconMailForward, link: "/dashboard/email" },
  { label: "Questions", icon: IconQuestionMark, link: "/dashboard/questions" },
  { label: "Tests", icon: IconZoomQuestion, link: "/dashboard/tests" },
];

const useStyles = createStyles((theme) => ({
  navbar: {
    backgroundColor:
      theme.colorScheme === "dark" ? theme.colors.dark[6] : theme.white,
    paddingBottom: 0,
  },

  header: {
    padding: theme.spacing.md,
    paddingTop: 0,
    marginLeft: -theme.spacing.md,
    marginRight: -theme.spacing.md,
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
    borderBottom: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,
  },

  links: {
    marginLeft: -theme.spacing.md,
    marginRight: -theme.spacing.md,
  },

  linksInner: {
    paddingTop: theme.spacing.xl,
    paddingBottom: theme.spacing.xl,
  },

  footer: {
    marginLeft: -theme.spacing.md,
    marginRight: -theme.spacing.md,
    borderTop: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,
  },
}));

export function NavbarNested({ isNavOpen }) {
  const { classes } = useStyles();
  const { colorScheme, toggleColorScheme } = useMantineColorScheme();

  const links = mockdata.map((item) => (
    <LinksGroup {...item} key={item.label} />
  ));

  return (
    <Navbar
      height={"calc(100vh)"}
      p="md"
      className={classes.navbar}
      hiddenBreakpoint="sm"
      hidden={!isNavOpen}
      width={{ sm: 200, lg: 300 }}
    >
      <Navbar.Section className={classes.header}>
        <Group position="apart">
          <Text>FAA</Text>
          <ActionIcon
            onClick={() => toggleColorScheme()}
            size="lg"
            sx={(theme) => ({
              backgroundColor:
                theme.colorScheme === "dark"
                  ? theme.colors.dark[6]
                  : theme.colors.gray[0],
              color:
                theme.colorScheme === "dark"
                  ? theme.colors.yellow[4]
                  : theme.colors.blue[6],
            })}
          >
            {colorScheme === "dark" ? (
              <IconSun size={18} />
            ) : (
              <IconMoonStars size={18} />
            )}
          </ActionIcon>
        </Group>
      </Navbar.Section>

      <Navbar.Section grow className={classes.links} component={ScrollArea}>
        <div className={classes.linksInner}>{links}</div>
      </Navbar.Section>

      <Navbar.Section className={classes.footer}>
        <UserButton image="https://images.unsplash.com/photo-1508214751196-bcfd4ca60f91?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=255&q=80" />
      </Navbar.Section>
    </Navbar>
  );
}
