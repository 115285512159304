import {
  Box,
  Button,
  Card,
  Group,
  Loader,
  SimpleGrid,
  Text,
} from "@mantine/core";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { IconPlus, IconTrash } from "@tabler/icons";

import { setHeaderTitle } from "../../store/modules/static";
import CreateCourse from "../../components/course/CreateCourse";
import useAxios from "../../hooks/axios";
import { useMutation, useQuery } from "@tanstack/react-query";
import courseApi from "../../services/api/course";
import { Link } from "react-router-dom";

const AllCourse = () => {
  const [isCreateCourseModalOpened, setCreateCourseModalOpened] =
    useState(false);
  const { api: axiosApi } = useAxios();

  const courses = useQuery(["course"], () => courseApi(axiosApi).getAll());
  const deleteCourseMutation = useMutation((courseId) => {
    return courseApi(axiosApi).deleteCourse(courseId);
  });

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      setHeaderTitle({
        title: "Manage Courses",
        subtitle: "Manage all your courses",
      })
    );
  }, [dispatch]);

  const callbackAfterCreatingCourse = (course) => {
    setCreateCourseModalOpened(false);
    courses.refetch();
  };

  const deleteCourse = async (courseId) => {
    await deleteCourseMutation.mutateAsync(courseId);
    courses.refetch();
  };

  if (courses.isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "80vh",
        }}
      >
        <Loader />
      </Box>
    );
  }

  return (
    <>
      {/* <CreateCourse
        isOpened={isCreateCourseModalOpened}
        onClose={() => {
          setCreateCourseModalOpened(false);
        }}
        onCreated={callbackAfterCreatingCourse}
      /> */}
      {/* <Group position="right">
        <Button
          leftIcon={<IconPlus />}
          onClick={() => setCreateCourseModalOpened(true)}
        >
          Create Course
        </Button>
      </Group> */}
      <SimpleGrid cols={3} sx={{ marginTop: 20 }}>
        {courses.data.data.courses.map((course) => {
          return (
            <Card
              key={course._id}
              withBorder
              p="lg"
              radius="md"
              sx={{
                position: "relative",
              }}
            >
              <Box
                mb="sm"
                component={Link}
                to={`/dashboard/course/${course._id}`}
              >
                <Text weight={500}>{course.title}</Text>
                <Group align="start" position="apart">
                  <Text size="sm" color="gray">
                    {course.classCount} classes <br />
                    {secondsToHours(course.availableWatchDuration)} Hours
                  </Text>
                  <Text size="sm" color="gray">
                    {secondsToHours(course.watchDuration)} Hours watched (all
                    users) <br />
                  </Text>
                </Group>
              </Box>
              {/* <Box mt={20}>
                <Group position="right">
                  <Button
                    color="red"
                    size="xs"
                    leftIcon={<IconTrash size={14} />}
                    onClick={() => {
                      deleteCourse(course.id);
                    }}
                  >
                    Delete
                  </Button>
                </Group>
              </Box> */}
            </Card>
          );
        })}
      </SimpleGrid>
    </>
  );
};

export default AllCourse;

function secondsToHours(seconds) {
  return Number(seconds / 3600).toFixed(2);
}
