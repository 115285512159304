import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { setHeaderTitle } from "../../store/modules/static";
import {
  Box,
  Button,
  Drawer,
  Group,
  Modal,
  SegmentedControl,
  Text,
  TextInput,
  TypographyStylesProvider,
} from "@mantine/core";
import emailApi from "../../services/api/email";
import useAxios from "../../hooks/axios";
import { Editor } from "@tinymce/tinymce-react";
import { showNotification } from "@mantine/notifications";

const Email = () => {
  const dispatch = useDispatch();
  const [value, onChange] = useState("");
  const [sendingTo, setSendingTo] = useState("test");
  const [subject, setSubject] = useState();
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [sendingEmail, setSendingEmail] = useState(false);
  const { api: axiosApi } = useAxios();
  const editorRef = useRef(null);

  useEffect(() => {
    dispatch(
      setHeaderTitle({
        title: "Send Email",
        subtitle: "Send email to all users",
      })
    );
  }, [dispatch]);

  const sendEmail = async () => {
    setSendingEmail(true);
    try {
      const req = await emailApi(axiosApi).send({
        type: sendingTo,
        subject: subject,
        message: editorRef.current.getContent(),
      });
      setSendingEmail(false);
      if (sendingTo !== "test") {
        setSubject("");
        onChange("");
      }
      setIsConfirmDialogOpen(false);
      showNotification({
        title: "Success",
        message: "Email sent successfully",
        color: "green",
      });
    } catch (error) {
      setSendingEmail(false);
      console.log(error);
    }
  };

  return (
    <Box>
      <Modal
        opened={isConfirmDialogOpen}
        onClose={() => setIsConfirmDialogOpen(false)}
      >
        <Box>
          <Text size="md" transform="capitalize" weight={500} mb={5}>
            You are about to send this email
          </Text>
          <Text size="sm">
            Please confirm that you want to send this email, note that this
            action can't be reversed.
          </Text>
        </Box>
        <Group position="right" mt="md">
          <Button
            variant="outline"
            color="red"
            onClick={() => setIsConfirmDialogOpen(false)}
            disabled={sendingEmail}
          >
            Cancel
          </Button>
          <Button
            variant="outline"
            color="blue"
            onClick={sendEmail}
            loading={sendingEmail}
          >
            Send
          </Button>
        </Group>
      </Modal>

      <Drawer
        position="right"
        opened={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
        size="xl"
      >
        <Box p="md">
          <TypographyStylesProvider>
            <div dangerouslySetInnerHTML={{ __html: value }} />
          </TypographyStylesProvider>
        </Box>
      </Drawer>

      <Group position="right">
        <SegmentedControl
          value={sendingTo}
          onChange={setSendingTo}
          data={[
            { label: "Test", value: "test" },
            { label: "All Users", value: "all" },
          ]}
        />
        <Button onClick={() => setIsDrawerOpen(true)} ml="md">
          Preview Email
        </Button>
        <Button onClick={() => setIsConfirmDialogOpen(true)}>Send Email</Button>
      </Group>
      <Box mt={40}>
        <TextInput
          label="Subject"
          placeholder="Enter email subject"
          mb="md"
          onChange={(e) => setSubject(e.currentTarget.value)}
        />
        <Editor
          apiKey="bi2fpfcxlbjimopo6hx5ug8c1ik7dlb7ek1u00y7pf0qbsiq"
          onInit={(evt, editor) => (editorRef.current = editor)}
          initialValue=""
          init={{
            height: 500,
            menubar: true,
            plugins: [
              "advlist",
              "autolink",
              "lists",
              "link",
              "image",
              "charmap",
              "preview",
              "anchor",
              "searchreplace",
              "visualblocks",
              "code",
              "fullscreen",
              "insertdatetime",
              "media",
              "table",
              "code",
              "help",
              "wordcount",
            ],
            toolbar:
              "undo redo | blocks | " +
              "bold italic forecolor | alignleft aligncenter " +
              "alignright alignjustify | bullist numlist outdent indent | " +
              "removeformat | help",
            content_style:
              "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
          }}
        />
      </Box>
    </Box>
  );
};

export default Email;
