export const useQueryParams = (location) => {
  const searchParams = new URLSearchParams(location.search);
  const params = {};

  for (const [key, value] of searchParams.entries()) {
    params[key] = value;
  }

  return params;
}

export default useQueryParams;