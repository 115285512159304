import axios from "axios";
import { showNotification } from "@mantine/notifications";
import React from "react";
import { useContext } from "react";
import { useMemo } from "react";
import { createContext } from "react";
import { useNavigate } from "react-router-dom";
import useAuth from "./auth";

const AxiosContext = createContext({
  api: null,
});

export const AxiosProvider = ({ children }) => {
  const { user, logout } = useAuth();
  const navigate = useNavigate();

  const instance = useMemo(() => {
    const isDev = process.env.NODE_ENV === "development";

    const apiService = axios.create({
      baseURL: isDev
        ? "http://localhost:3030/api/v1"
        : "https://api.futureacademy.africa/api/v1",
    });

    apiService.interceptors.request.use((config) => {
      const token = user ? user.token : null;
      console.log("user", user);
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    });

    apiService.interceptors.response.use(
      (response) => {
        return response;
      },
      function (error) {
        console.log(error.response.data.message);
        if (error.response.data.message === "Unauthorized") {
          showNotification({
            title: "Error",
            message: "Unauthorized, kindly login again",
            color: "red",
          });
          logout(() => {
            navigate("/");
          });
        }
        if (error.message === "Network Error") {
          showNotification({
            title: "Network Error",
            message: "Please check your internet connection",
            color: "red",
          });
        }
        return Promise.reject(error);
      }
    );

    return apiService;
  }, [navigate, user]);

  return (
    <AxiosContext.Provider value={{ api: instance }}>
      {children}
    </AxiosContext.Provider>
  );
};

const useAxios = () => {
  return useContext(AxiosContext);
};

export default useAxios;
