import {
  Box,
  Button,
  Card,
  CopyButton,
  FileInput,
  Group,
  Loader,
  Modal,
  Select,
  SimpleGrid,
  Switch,
  Text,
  TextInput,
  Textarea,
} from "@mantine/core";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { IconPlus, IconTrash } from "@tabler/icons";

import { setHeaderTitle } from "../../store/modules/static";
import useAxios from "../../hooks/axios";
import { useMutation, useQuery } from "@tanstack/react-query";
import courseApi from "../../services/api/course";
import { Link } from "react-router-dom";
import { useForm, isNotEmpty } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import quizApi from "../../services/api/quiz";
import { showNotification } from "@mantine/notifications";

const Quiz = () => {
  const { api: axiosApi } = useAxios();

  const courses = useQuery(["course"], () => courseApi(axiosApi).getAll());
  const quizzes = useQuery(["quiz"], () => quizApi(axiosApi).getAll());

  const [createQuizModal, handleCreateQuizModal] = useDisclosure(false);
  const [isCreatingQuiz, setIsCreatingQuiz] = useState(false);
  const [editQuiz, setEditQuiz] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      setHeaderTitle({
        title: "Manage Tests",
        subtitle: "Create and check test info",
      })
    );
  }, [dispatch]);

  const createQuizForm = useForm({
    initialValues: {
      course: "",
      title: "",
      description: "",
      file: "",
      passPercentage: "",
      durationInSeconds: "",
      method: "Append",
    },
    validate: {
      course: (value) => (value.length > 0 ? null : "Required"),
      title: (value) => (value.length > 0 ? null : "Required"),
      numberOfQuestions: (value) => (value.length > 0 ? null : "Required"),
      passPercentage: (value) => (value.length > 0 ? null : "Required"),
      file: (value) => (value !== null ? null : "Required"),
    },
  });

  const createQuiz = async () => {
    setIsCreatingQuiz(true);
    try {
      const formData = new FormData();

      for (const key in createQuizForm.values) {
        if (Object.hasOwnProperty.call(createQuizForm.values, key)) {
          const value = createQuizForm.values[key];
          formData.append(key, value);
        }
      }

      if (editQuiz) {
        await quizApi(axiosApi).update(editQuiz._id, formData);
      } else {
        await quizApi(axiosApi).create(formData);
      }

      showNotification({
        title: "Successful",
        color: "green",
        message: `${editQuiz ? "Quiz updated" : "Quiz created"} successfully`,
      });
      quizzes.refetch();
      setIsCreatingQuiz(false);
      handleCreateQuizModal.close();
    } catch (error) {
      console.log(error);

      showNotification({
        title: "Error",
        message: error?.message,
        color: "red",
      });
      setIsCreatingQuiz(false);
    }
  };

  const handleEditQuiz = (quiz) => {
    createQuizForm.setValues({
      course: quiz.course._id,
      title: quiz.title,
      description: quiz.description,
      passPercentage: quiz.passPercentage,
      durationInSeconds: quiz.durationInSeconds,
    });
    setEditQuiz(quiz);
    handleCreateQuizModal.open();
  };

  return (
    <>
      {!courses.isLoading && (
        <Modal
          opened={createQuizModal}
          onClose={handleCreateQuizModal.close}
          title={`${editQuiz ? "Edit Quiz" : "Create Quiz"}`}
        >
          <form onSubmit={createQuizForm.onSubmit}>
            <div className="space-y-3">
              <TextInput
                label="Test Title"
                {...createQuizForm.getInputProps("title")}
              />
              <TextInput
                label="Test Duration in Seconds"
                description="Leave blank of use 0 from to time limit"
                {...createQuizForm.getInputProps("durationInSeconds")}
              />
              <TextInput
                label="Pass Percentage (in %)"
                placeholder="e.g 50"
                prefix="%"
                type="number"
                {...createQuizForm.getInputProps("passPercentage")}
              />
              <Textarea
                label="Description"
                {...createQuizForm.getInputProps("description")}
              />
              <div>
                <div>
                  <FileInput
                    label="Select question file"
                    {...createQuizForm.getInputProps("file")}
                  />
                </div>
              </div>
              {editQuiz && (
                <div>
                  <Switch
                    label="Override questions"
                    checked={createQuizForm.values.method === "Override"}
                    onChange={(event) =>
                      createQuizForm.setFieldValue(
                        "method",
                        event.target.checked ? "Override" : "Append"
                      )
                    }
                  />
                </div>
              )}
              <Select
                data={courses?.data?.data?.courses?.map((c) => ({
                  value: c._id,
                  label: c.title,
                }))}
                label="Course"
                {...createQuizForm.getInputProps("course")}
              />
            </div>
            <div className="mt-5">
              <Button
                onClick={createQuiz}
                loading={isCreatingQuiz}
                type="submit"
              >
                {editQuiz ? "Update Quiz" : "Create Quiz"}
              </Button>
            </div>
          </form>
        </Modal>
      )}

      <Group position="right">
        <Button onClick={handleCreateQuizModal.open}>Create Quiz</Button>
      </Group>

      {quizzes.isLoading ? (
        <>
          <div>
            <Loader />
          </div>
        </>
      ) : (
        <SimpleGrid cols={3} sx={{ marginTop: 20 }}>
          {quizzes.data.data.map((q) => (
            <Card key={q._id} shadow="lg">
              <div className="font-medium text-base">{q.title}</div>
              <div className="text-sm text-gray-700">{q.course?.title}</div>
              <div className="text-sm text-gray-500">{q.description}</div>
              <div className="grid grid-cols-3 text-xs justify-between text-gray-500 mt-4">
                <span>{q.questions.length} Questions</span>
                <span>{q.passPercentage}%</span>
                <span>{q.durationInSeconds}s</span>
              </div>
              <div className="flex items-center gap-5 mt-5">
                <div className="grid grid-cols-3">
                  <div className="col-span-2">
                    <CopyButton value={q.quizLink}>
                      {({ copied, copy }) => (
                        <Button
                          variant="filled"
                          color="blue"
                          fullWidth
                          radius="md"
                          onClick={copy}
                        >
                          {copied ? "Copied!" : "Copy link"}
                        </Button>
                      )}
                    </CopyButton>
                  </div>
                </div>
                <Button variant="outline" onClick={() => handleEditQuiz(q)}>
                  View Info
                </Button>
                <Button variant="outline" onClick={() => handleEditQuiz(q)}>
                  Edit
                </Button>
              </div>
            </Card>
          ))}
        </SimpleGrid>
      )}
    </>
  );
};

export default Quiz;
