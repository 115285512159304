import { ColorSchemeProvider, MantineProvider } from "@mantine/core";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Provider } from "react-redux";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { NotificationsProvider } from "@mantine/notifications";
import { AxiosProvider } from "./hooks/axios";

import { AuthProvider } from "./hooks/auth";
import { store } from "./store/store";

import Dashboard from "./layout/dashboard";
import Login from "./pages/login";
import Home from "./pages/dashboard/home";
import AllCourse from "./pages/dashboard/course";
import CourseById from "./pages/dashboard/course/courseId";
import Email from "./pages/dashboard/email";
import Users from "./pages/dashboard/users";
import Questions from "./pages/dashboard/questions";
import ManageQuestion from "./pages/dashboard/questions/manageQuestions";
import Quiz from "./pages/dashboard/quiz";

function App() {
  const queryClient = new QueryClient({});

  return (
    <ColorSchemeProvider>
      <MantineProvider
        withGlobalStyles
        withNormalizeCSS
        style={{
          fontFamily: "Open Sans, sans serif",
        }}
      >
        <NotificationsProvider>
          <Provider store={store}>
            <QueryClientProvider client={queryClient}>
              <BrowserRouter>
                <AuthProvider>
                  <AxiosProvider>
                    <Routes>
                      <Route path="/" element={<Login />} />
                      <Route path="/dashboard" element={<Dashboard />}>
                        <Route path="home" element={<Home />} />
                        <Route path="users" element={<Users />} />
                        <Route path="course" element={<AllCourse />} />
                        <Route path="course/:id" element={<CourseById />} />
                        <Route path="email" element={<Email />} />
                        <Route path="questions" element={<Questions />} />
                        <Route
                          path="questions/:courseId"
                          element={<ManageQuestion />}
                        />
                        <Route path="tests" element={<Quiz />} />
                      </Route>
                    </Routes>
                  </AxiosProvider>
                </AuthProvider>
              </BrowserRouter>
            </QueryClientProvider>
          </Provider>
        </NotificationsProvider>
      </MantineProvider>
    </ColorSchemeProvider>
  );
}

export default App;
