const authApi = (axios) => {
  return {
    login: async (data) => {
      try {
        const req = await axios.post("/admin/auth/login", data);
        return req.data;
      } catch (error) {
        throw error?.response?.data;
      }
    },
  };
};

export default authApi;
